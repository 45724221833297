import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import logoNoir from "../../static/logoNoir.svg"
import logoRouge from "../../static/logoRouge.svg"
import menuBtn from "../../static/menu.svg"
import crossBtn from "../../static/croix.svg"
import { LangContext } from "../hooks/lang.js"

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  let langContext = useContext(LangContext)
  let french = langContext && langContext.lang && langContext.lang === "fr-CA"
  let [hide, setHide] = useState(true)

  useEffect(() => {
    setTimeout(() => setHide(false), 3000)
  }, [])
  if (hide) {
    return null
  }
  if (menuOpen) {
    return (
      <nav className="fixed overlay">
        <div className="nav--open">
          <Link className="logo-container" to="/">
            <img src={logoRouge} alt="brand logo" />
          </Link>

          <button
            className="menu-icon-container"
            onClick={() => setMenuOpen(false)}
          >
            <img src={crossBtn} alt="cross icon" />
          </button>
        </div>

        <ul>
          {/* <li>
            <Link to="/collaborateurs">
              {french ? "Collaborateurs.trices" : "Collaborators"}
            </Link>
          </li> */}
          <li>
            <Link to="/projets">{french ? "Projets" : "Projects"}</Link>
          </li>
          {/* <li>
            <Link to="/services">Services</Link>
          </li> */}
          <li>
            <Link to="/apropos">{french ? "À Propos" : "About"}</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <button onClick={langContext.toggleLang}>
              {french ? "English" : "Français"}
            </button>
          </li>
        </ul>
      </nav>
    )
  }
  return (
    <nav className="nav">
      <div className="nav--closed">
        <Link className="logo-container" to="/">
          <img src={logoNoir} alt="brand logo" />
        </Link>
        <button
          className="menu-icon-container"
          onClick={() => setMenuOpen(true)}
        >
          <img src={menuBtn} alt="menu icon" />
        </button>
      </div>
    </nav>
  )
}

export default Nav
